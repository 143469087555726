/*设置localStorage*/
export function setLocalStorage (key, val) {
  if (!window.localStorage){
    alert("浏览器不支持localstorage")
    return false
  } else {
    let storage = window.localStorage
    /*转化为json字符串*/
    let json = JSON.stringify(val)
    /*写入数据*/
    storage.setItem(key, json)
  }
}
/*读取localStorage*/
export function getLocalStorage(val) {
  let storage = window.localStorage
  /*将JSON字符串转换成为JSON对象输出*/
  let json = storage.getItem(val)
  let jsonObj = JSON.parse(json)
  return jsonObj
}
/*删除localStorage某个字段*/
export function delLocalStorage(key) {
  let storage = window.localStorage
  storage.removeItem(key)
}
/*清空localStorage*/
export function clearLocalStorage() {
  let storage = window.localStorage
  storage.clear()
}
