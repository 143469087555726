import axios from "axios";
import router from "../../router";
import { baseUrl, uploadUrl, getToken, systemConfig } from "./request";
import { clearLocalStorage } from "../localStorage";
import sf from "string-format";
import { message } from "ant-design-vue";
import qs from 'qs'

/*不需要验证 formData*/
const axiosHttp = axios.create({
  baseURL: baseUrl,
  timeout: 30000
});
axiosHttp.interceptors.request.use(function(config) {
  message.destroy();
  message.loading("加载中...", 0);
  config.headers = {
    "content-type": "application/x-www-form-urlencoded"
  };
  config.transformRequest = [function(data) {
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    return ret;
  }];
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosHttp.interceptors.response.use(res => {
  setTimeout(() => {
    message.destroy();
  }, 1000);
  //身份异常或未登录
  if (res.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }
  if (res.data.code !== 200) {
    message.error(res.data.message);
  }
  return res.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

/*不需要验证 json*/
const axiosHttpJson = axios.create({
  baseURL: baseUrl,
  timeout: 30000
});
axiosHttpJson.interceptors.request.use(function(config) {
  message.destroy();
  message.loading("加载中...", 0);
  config.headers = {
    "content-type": "application/json"
  };
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosHttpJson.interceptors.response.use(res => {
  message.destroy();
  //身份异常或未登录
  if (res.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }
  if (res.data.code !== 200) {
    message.error(res.data.message);
  }
  return res.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

/*需要验证 formData*/
const axiosHttpVerification = axios.create({
  baseURL: baseUrl,
  timeout: 300000
});
axiosHttpVerification.interceptors.request.use(function(config) {
  message.destroy();
  message.loading("加载中...", 0);
  if (getToken()) {
    config.headers = {
      "x-access-token": getToken(),
      "content-type": "application/x-www-form-urlencoded"
    };
  } else {
    message.destroy();
    message.error("暂未登录,请登录");
    clearLocalStorage();
    router.push("/login");
  }
  config.transformRequest = [function(data) {
    let ret = "";
    for (let it in data) {
      ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
    }
    return ret;
  }];
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosHttpVerification.interceptors.response.use(res => {
  setTimeout(() => {
    message.destroy();
  }, 1000);
  //身份异常或未登录
  if (res.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }
  if (res.data.code !== 200) {
    message.error(res.data.message);
  }
  return res.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

/*需要验证 json*/
const axiosHttpVerificationJson = axios.create({
  baseURL: baseUrl,
  timeout: 30000
});
axiosHttpVerificationJson.interceptors.request.use(function(config) {
  message.destroy();
  message.loading("加载中...", 0);
  if (getToken()) {
    config.headers = {
      "x-access-token": getToken(),
      "content-type": "application/json"
    };
  } else {
    message.destroy();
    message.error("暂未登录,请登录");
    clearLocalStorage();
    router.push("/login");
  }
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosHttpVerificationJson.interceptors.response.use(res => {
  setTimeout(() => {
    message.destroy();
  }, 1000);
  //身份异常或未登录
  if (res.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }

  if (res.data.code !== 200) {
    message.error(res.data.message);
  }
  return res.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

/*上传需要验证*/
const axiosUpload = axios.create({
  baseURL: uploadUrl,
  timeout: 30000
  // withCredentials: true
});
axiosUpload.interceptors.request.use(function(config) {
  setTimeout(() => {
    message.destroy();
  }, 1000);
  message.loading("加载中...", 0);
  if (getToken()) {
    config.headers = {
      "x-access-token": getToken(),
      "Content-Type": "multipart/form-data"
    }
    config.onUploadProgress = progressEvent => {
      let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + "%";
      console.log(complete);
    };
  } else {
    message.destroy();
    message.error("暂未登录,请登录");
    clearLocalStorage();
    router.push("/login");
  }
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosUpload.interceptors.response.use(res => {
  message.destroy();
  //身份异常或未登录
  if (res.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }
  return res.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

/*需要验证 下载Excel文件*/
const axiosHttpVerificationExcel = axios.create({
  baseURL: baseUrl,
  timeout: 30000
});
axiosHttpVerificationExcel.interceptors.request.use(function(config) {
  message.destroy();
  message.loading("加载中...", 0);
  if (getToken()) {
    config.headers = {
      "x-access-token": getToken(),
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    };
    config.responseType = "arraybuffer";
  } else {
    message.destroy();
    message.error("暂未登录,请登录");
    clearLocalStorage();
    router.push("/login");
  }
  config.data = qs.stringify(config.data)
  return config;
}, function(error) {
  message.destroy();
  return Promise.reject(error);
});
axiosHttpVerificationExcel.interceptors.response.use(file => {
  setTimeout(() => {
    message.destroy();
  }, 1000);
  //身份异常或未登录
  if (file.data.code === systemConfig.userFailureCode) {
    clearLocalStorage();
    router.push("/login");
  }
  let fileNameArr = file.headers['content-type'].split(';')
  let fileName = ''
  fileNameArr.forEach((item) => {
    console.log(item.indexOf(".xls"))
    if(item.indexOf(".xls") != -1) {
      fileName = item
    }
  })
  fileName = decodeURI(escape(fileName))
  if(!fileName) {
    fileName = "充值模板.xls"
  }
  let content = file.data;
  // 组装a标签
  let elink = document.createElement("a");
  // 设置下载文件名
  elink.download = fileName;
  elink.style.display = "none";
  let blob = new Blob([content], { type: "application/zip" });
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
  return file.data;
}, error => {
  message.destroy();
  message.error("请求服务器失败，请检查网络是否正常！");
  return error;
});

export let baseRequest = {
  getV: (url, params, pathVariable = null) => axiosHttpVerification.get(sf(url, pathVariable), params),
  getJsonV: (url, params, pathVariable = null) => axiosHttpVerificationJson.get(sf(url, pathVariable), params),
  get: (url, params, pathVariable = null) => axiosHttp.get(sf(url, pathVariable), params),
  getJson: (url, params, pathVariable = null) => axiosHttpJson.get(sf(url, pathVariable), params),
  postV: (url, params, pathVariable = null) => axiosHttpVerification.post(sf(url, pathVariable), params),
  postJsonV: (url, params, pathVariable = null) => axiosHttpVerificationJson.post(sf(url, pathVariable), params),
  post: (url, params, pathVariable = null) => axiosHttp.post(sf(url, pathVariable), params),
  postJson: (url, params, pathVariable = null) => axiosHttpJson.post(sf(url, pathVariable), params),
  upload: (url, params, pathVariable = null) => axiosUpload.post(sf(url, pathVariable), params),
  downLoadExcel: (url, params, pathVariable = null) => axiosHttpVerificationExcel.post(sf(url, pathVariable), params)
};

