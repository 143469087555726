<template>
  <div class="home-wrapper">
    <div class="home-menu-wrapper">
      <div class="home-menu-logo">
        <img src="../../assets/logo.png">
        <h1>ETC企业管理后台</h1>
      </div>
      <a-menu
        :default-selected-keys="[defaultSelectedKeys]"
        mode="inline"
        theme="dark"
        :inline-collapsed="collapsed"
      >
        <a-menu-item v-show="!item.meta.hideInMenu" v-for="(item) in menuList" :key="item.name" @click="goPage(item)">
          <div class="home-menu-list-item">
            <img v-if="item.meta.icon" :src="require('@/assets/'+item.name+'.svg')" />
            <span>{{ item.meta.title }}</span>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
    <div class="home-body-wrapper">
      <div class="home-body-header">
        <span class="home-body-header-action" @click="login">
          <span>退出</span>
          <a-icon type="poweroff" style="color: #fff;margin-left: 5px" />
        </span>
      </div>
      <div class="home-body-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data() {
    return {
      defaultSelectedKeys: 'ETCVehicleManagement',
      collapsed: false,
      menuList: [],
      selectIndex: 0
    };
  },  methods: {
    //退出登录
    login() {
      let self = this
      this.$http.postJsonV('/companyUser/loginOut').then(res => {
        if(res.code === 200) {
          self.$router.push("/login");
        }
      })
    },
    /*跳转页面*/
    goPage(item) {
      this.$router.push(item.path);
    }
  },
  created() {
    this.menuList = this.$router.options.routes
    console.log(this.menuList[0])
    if(this.$route.name === 'index') {
      let flag = false
      this.menuList.forEach((item) => {
        if(!item.meta.hideInMenu && flag === false) {
          flag = true
          this.defaultSelectedKeys = item.name
        }
      })
    } else {
      this.defaultSelectedKeys = this.$route.name
    }
  }
};
</script>

<style scoped>
.home-wrapper {
  display: flex;
}

.home-menu-wrapper {
  flex: 0 0 256px;
  max-width: 256px;
  min-width: 256px;
  width: 256px;
  position: relative;
  z-index: 10;
  min-height: 100vh;
  -webkit-box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
  box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
  background: #001529;
  transition: all .2s;
}

.home-menu-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  -webkit-transition: all .3s;
  transition: all .3s;
  line-height: 64px;
  background: #001529;
}

.home-menu-logo > img {
  width: 32px;
  height: 32px;
}

.home-menu-logo > h1 {
  color: #fff;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  vertical-align: middle;
}

.home-body-wrapper {
  padding-left: 0;
  min-height: 100vh;
}

.home-body-header {
  position: relative;
  min-width: calc(100vw - 256px);
  height: 68px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #001529;
  color: #FFFFFF;
  -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 35%);
  box-shadow: 0 1px 4px rgb(0 21 41 / 35%);
}

.home-body-header-action {
  font-size: 14px;
}

.home-body-header-action:hover {
  cursor: pointer;
}

.home-body-content {
  padding: 20px;
  min-height: calc(100vh - 68px);
  background-color: #EEF0F3;
}

.home-menu-list-item {
  display: flex;
  align-items: center;
}

.home-menu-list-item > img {
  margin-right: 5px;
}
</style>