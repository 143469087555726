<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {getToken} from "@/utils/request/request";

export default {
  created() {
    let path = window.location.href
    let pathFlag = path.search("/login") !== -1
    if(!pathFlag) {
      if(!getToken()) {
         this.goLogin()
      }
    }
    this.$router.beforeEach((to, from, next) => {
      if(to.path !== "/login") {
        if(!getToken()) {
           this.goLogin()
        }
      }
      next();
    });
  },
  methods: {
    goLogin() {
      // this.$message.error('请登录')
      setTimeout(() => {
         this.$router.push('/login')
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
