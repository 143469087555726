import home from "../views/home/index";
/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面不会缓存
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 * }
 */

export default [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      hideInMenu: true,
      title: "首页",
      icon: ""
    },
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/ETCVehicleManagement/index")
      }
    ]
  },
  {
    path: "/ETCVehicleManagement",
    name: "ETCVehicleManagement",
    component: home,
    meta: {
      hideInMenu: false,
      title: "ETC车辆管理",
      icon: "car"
    },
    children: [
      {
        path: "/ETCVehicleManagement",
        name: 'ETCVehicleManagement',
        meta: {
          hideInMenu: false,
          title: "ETC车辆管理",
          icon: "car"
        },
        component: () => import('../views/ETCVehicleManagement/index')
      }
    ]
  },
  {
    path: "/ETCRechargePayment",
    name: "ETCRechargePayment",
    component: home,
    meta: {
      hideInMenu: false,
      title: "ETC充值打款",
      icon: "wallet"
    },
    children: [
      {
        path: "/ETCRechargePayment",
        name: 'ETCRechargePayment',
        meta: {
          hideInMenu: false,
          title: "ETC充值打款",
          icon: "wallet"
        },
        component: () => import('../views/ETCRechargePayment/index')
      }
    ]
  },
  {
    path: "/ETCRechargeRecord",
    name: "ETCRechargeRecord",
    component: home,
    meta: {
      hideInMenu: false,
      title: "ETC充值记录",
      icon: "snippets"
    },
    children: [
      {
        path: "/ETCRechargeRecord",
        name: 'ETCRechargeRecord',
        meta: {
          hideInMenu: false,
          title: "ETC充值记录",
          icon: "snippets"
        },
        component: () => import('../views/ETCRechargeRecord/index')
      }
    ]
  },
  {
    path: "/passageBill",
    name: "passageBill",
    component: home,
    meta: {
      hideInMenu: false,
      title: "通行账单",
      icon: "container"
    },
    children: [
      {
        path: "/passageBill",
        name: 'passageBill',
        meta: {
          hideInMenu: false,
          title: "通行账单",
          icon: "container"
        },
        component: () => import('../views/passageBill/index')
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      hideInMenu: true
    },
    component: () => import("../views/login/index.vue")
    // component: () => import("../views/login/test.vue")
  },
]
