import {getLocalStorage} from "../localStorage";

const httpUrl = {
    // dev: 'http://192.168.101.59:8083',
    dev: 'http://47.111.165.45:9081',
    // dev: 'http://118.31.120.41:9081',
    pro: 'https://carbus.51etr.com'
}
const httpUpload = {
    dev: 'http://118.31.120.41:9081',
    pro: 'https://carbus.51etr.com'
}
const getHttpUpload = {
    dev: 'http://118.31.120.41:9081',
    pro: 'https://carbus.51etr.com'
}

const httpCurrent = 'pro'

export const baseUrl = httpCurrent === 'dev' ? httpUrl.dev : httpUrl.pro
export const uploadUrl = httpCurrent === 'dev' ? httpUpload.dev : httpUpload.pro
export const getUploadUrl = httpCurrent === 'dev' ? getHttpUpload.dev : getHttpUpload.pro

export const systemConfig = {
    userFailureCode: 10000 //用户失效状态
}

export function getToken() {
    let user = getLocalStorage('etc-bms-user')
    if (!user || typeof (user) === 'undefined') {
        return false
    }
    let token = user.token
    if (token || typeof (token) !== 'undefined') {
        return token
    } else {
        return false
    }
}
