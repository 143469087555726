import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/common/css/reset.css';
import {baseRequest} from "./utils/request/axios-cfg";
import {VueJsonp} from 'vue-jsonp';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
Vue.use(Viewer);

Vue.use(VueJsonp);
Vue.config.productionTip = false
Vue.use(Antd);

/*配置axios全局*/
Vue.prototype.$http = baseRequest;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
